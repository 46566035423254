import { Box, Stack, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { heroStyles } from './styles';

type Props = {
  translate: (key: string) => string;
};

export default function Hero({ translate }: Props) {
  return (
    <Box sx={heroStyles.container}>
      <Stack sx={heroStyles.content}>
        <Typography component="h1" variant="subtitle1" sx={heroStyles.label}>
          {translate('aboutUs.heroLabel')}
        </Typography>
        <Typography variant="h1" sx={heroStyles.title}>
          {translate('aboutUs.heroTitle')}
        </Typography>
        <Typography variant="subtitle1" sx={heroStyles.subtitle}>
          {translate('aboutUs.heroSubtitle')}
        </Typography>
        <Link component={RouterLink} to="/kontakt" sx={heroStyles.button}>
          <Typography variant="subtitle1">
            {translate('aboutUs.heroButton')}
          </Typography>
        </Link>
      </Stack>
    </Box>
  );
}
