// styles.js
export const heroStyles = {
  container: {
    backgroundSize: 'cover',
    backgroundImage:
      'url(https://storage.gutgeregelt.ch/assets/backgrounds/about_us_hero.webp)',
    backgroundRepeat: 'no-repeat',
  },
  content: {
    maxWidth: '1240px',
    margin: '0 auto',
    paddingY: 4,
    paddingX: 2,
  },
  label: {
    paddingY: 0.5,
    paddingX: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 5,
    width: 'fit-content',
    color: 'white',
    fontWeight: 'light',
  },
  title: {
    color: 'white',
    width: {
      lg: '50%',
      xs: '100%',
    },
  },
  subtitle: {
    color: 'white',
    width: '50%',
  },
  button: {
    color: '#3366FF',
    backgroundColor: '#f5f5f5',
    width: 'fit-content',
    textDecoration: 'none',
    px: 2,
    py: 0.5,
    borderRadius: 1,
    // Hover effect
    '&:hover': {
      backgroundColor: '#3366FF',
      color: 'white',
      transition: 'all 0.3s ease',
    },
  },
};

export const sectionStyles = {
  container: {
    display: {
      xs: 'block',
      md: 'flex',
    },
    flexDirection: 'row',
    gap: 4,
    maxWidth: '1240px',
    margin: '0 auto',
    paddingY: 4,
    paddingX: 2,
  },
  content: {
    width: {
      xs: '100%',
      lg: '60%',
    },
    spacing: {
      xs: 1,
      lg: 2,
    },
  },
  image: {
    width: {
      xs: '100%',
      lg: '450px',
    },
    height: {
      xs: 'auto',
      lg: 'auto',
    },
  },
};

export const featureSectionStyles = {
  container: {
    maxWidth: '1240px',
    margin: '0 auto',
    paddingY: 4,
    paddingX: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    alignItems: 'center',
    textAlign: 'center',
  },
  label: {
    paddingX: 2,
    paddingY: 0.5,
    backgroundColor: '#1939b7',
    borderRadius: 5,
    width: 'fit-content',
    color: 'white',
    fontWeight: 'light',
    zIndex: 4,
  },
};
