import { Box, Typography } from '@mui/material';
import { featureSectionStyles } from './styles';

type Props = {
  label: string;
  title: string;
  subtitle: string;
  sx?: any;
};

export default function FeatureSection({ label, title, subtitle, sx }: Props) {
  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <Box sx={featureSectionStyles.container}>
        <Typography
          component="h1"
          variant="subtitle1"
          sx={featureSectionStyles.label}
        >
          {label}
        </Typography>
        <Typography variant="h2" component="h2" sx={{ zIndex: 2 }}>
          {title}
        </Typography>
        <Typography variant="body2" fontSize="1rem" sx={{ zIndex: 2 }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
