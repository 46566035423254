import { Box, Stack, Typography } from '@mui/material';
import Image from '#/components/shared/ui/Image';
import { sectionStyles } from './styles';

type SectionProps = {
  imageUrl: string;
  title: string;
  subtitle: string;
  imageFirst?: boolean;
  imageSX?: any;
  sx?: any;
};

export default function Section({
  imageUrl,
  title,
  subtitle,
  imageFirst,
  imageSX,
  sx,
}: SectionProps) {
  return (
    <Box
      sx={{
        ...sectionStyles.container,
        ...sx,
      }}
    >
      {imageFirst ? (
        <>
          <Image
            src={imageUrl}
            alt="About Us"
            sx={{
              ...sectionStyles.image,
              borderTopRightRadius: {
                xs: '0',
                lg: '50px',
              },
              borderBottomLeftRadius: {
                xs: '50px',
                lg: '50px',
              },
            }}
          />
          <ContentStack title={title} subtitle={subtitle} />
        </>
      ) : (
        <>
          <ContentStack title={title} subtitle={subtitle} />
          <Image
            src={imageUrl}
            alt="About Us"
            sx={{
              ...(imageSX || sectionStyles.image),
            }}
          />
        </>
      )}
    </Box>
  );
}

type ContentStackProps = {
  title: string;
  subtitle: string;
};

function ContentStack({ title, subtitle }: ContentStackProps) {
  return (
    <Stack sx={sectionStyles.content}>
      <Typography variant="h2" component="h2">
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" fontSize="1rem">
        {subtitle}
      </Typography>
    </Stack>
  );
}
